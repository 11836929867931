import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { LoggingService } from '@core/services/logging.service';
import { NavigationService } from '@core/services/navigation.service';
import { SeoService } from '@core/services/seo.service';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { BaseComponent } from '@standalone/base-component.component';
import { AppInsightsService } from '@core/services/app-insights.service';
import { ProductService } from '@core/services/product.service';
import { environment } from '@environment/environment';
import { CookieService } from '@core/services/cookie.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-main',
  template: `<ng-container>
      <div style="min-height: 42px; background-color: #eae7e4">
        @defer (on viewport) {
        <klarna-placement
          class="klarna-placement-header"
          data-key="top-strip-promotion-auto-size"
          data-locale="sv-SE"
        ></klarna-placement>
        } @placeholder {
        <skeleton [skeletonHeight]="42" [fullWidth]="'100vw'"></skeleton>
        }
      </div>
    </ng-container>
    <div class="main-container">
      <core-header></core-header>
      <div [class.canBlur]="true" class="sub-container">
        <core-breadcrumbs></core-breadcrumbs>
        <core-language></core-language>
      </div>
      <core-page-content [class.canBlur]="true">
        <router-outlet></router-outlet>
      </core-page-content>
      <core-footer [class.canBlur]="true"></core-footer>
    </div>

    <core-to-checkout></core-to-checkout>
    <toast></toast>
    <cookie-consent></cookie-consent>
    <loading></loading>
    <structured-data></structured-data>`,
  styles: [
    `
      @import '/src/styles/variables';
      .sub-container {
        display: flex;
        border-bottom: 1px solid $primary;
        height: 33px;
        margin-bottom: 7px;

        @media (min-width: 1300px) {
          width: 75%;
          margin: auto auto 7px auto;
        }
      }
      .main-container {
        position: relative;
        flex-grow: 1;
        width: 100%;
        max-width: $maxWidth;
        margin: auto;
        min-height: calc(100vh - $klarnaPlacementHeight);
      }
    `,
  ],
})
export class MainComponent extends BaseComponent {
  protected isTest = environment.isTest;
  constructor(
    private appInsightsService: AppInsightsService,
    private authService: AuthenticationService,
    private productService: ProductService,
    private loggingService: LoggingService,
    private cookieService: CookieService,
    private cookieConsentService: CookieConsentService,
    private navigationService: NavigationService,
    private seoService: SeoService
  ) {
    super();
  }
}
